import '../App.scss';
import { Questionnaire } from '../components/questionnaire';

function Questions() {

  return (
    <Questionnaire enabled={true} />
  );
}

export default Questions;