import React, { useState } from 'react';
import './App.scss';

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import Header from './components/header';
import Footer from './components/footer';
import ComingSoon from './routes/comingSoon';
import Questions from './routes/questions';
import Registration from './routes/registration';
import Project from './routes/project';
import Recommendations from './routes/recommendations';
import ThankYou from './routes/thankYou';
import Onboarding from './routes/onboarding';
import Guide from './routes/guide';
import Report from './routes/report';
import Terms from './routes/terms';

import ErrorBoundary from './components/errorBoundary';
import NotFound from './components/notFound';

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <ComingSoon />,
    },
    {
      path: "/registration",
      element: <Registration />,
    },
    {
      path: "/questions",
      element: <Questions />
    },
    {
      path: "project/",
      element: <Project />,
      errorElement: <NotFound />
    },
    {
      path: "/recommendations",
      element: <Recommendations />,
      errorElement: <NotFound />
    },
    {
      path: "/thank-you",
      element: <ThankYou />,
    },
    {
      path: "/onboarding/",
      element: <Onboarding />,
    },
    {
      path: "/guide/",
      element: <Guide />,
    },
    {
      path: "/report",
      element: <Report />,
      errorElement: <NotFound />
    },
    {
      path: "/terms",
      element: <Terms />,
    },
    {
      path: "*",
      element: <NotFound />,
    },
  ]);

  return (
    <div className="App">
      <Header />
      <ErrorBoundary>
        <RouterProvider router={router} />
      </ErrorBoundary>
      <Footer />
    </div>
  );
}

export default App;

//export const APIPATH = 'https://ourjourneyapi.azurewebsites.net';
//export const APIPATH = 'http://localhost:5148';

export const APIPATH = 'https://ourjourney2-c3hmd4cdeub6h5ah.uksouth-01.azurewebsites.net';