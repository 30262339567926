
import { useState } from 'react';
import '../App.scss';

function Terms() {

  return (
    <div className="main container pb-4 d-flex flex-column  mb-4">
      <h1 className="mb-6">Terms &amp; Conditions</h1>
      <h1>Software License Agreement</h1>
      <p>The use of this Software License Agreement ("Agreement") is made and entered into as of the date of purchase ("Effective Date") by and between all parties.</p>

      <h3>1. Grant of License</h3>
      <p><strong>1.1. License:</strong> Subject to the terms and conditions of this Agreement, Licensor hereby grants Licensee a non-exclusive, non-transferable, single-use license to use the software application ("Software") solely for the purpose of executing one project and generating one report ("Project").</p>

      <h3>2. Restrictions</h3>
      <p><strong>2.1. Single Use:</strong> Licensee is authorised to use the Software only once for one Project and to generate one report.</p>
      <p><strong>2.2. No Transfer:</strong> Licensee shall not sublicense, sell, rent, lease, transfer, assign, or distribute the Software or any copies thereof to any third party.</p>
      <p><strong>2.3. No Modifications:</strong> Licensee shall not modify, adapt, translate, reverse engineer, decompile, disassemble, or create derivative works based on the Software.</p>
      <p><strong>2.4. No Multiple Uses:</strong> Licensee shall not use the Software for more than one Project or to generate more than one report without obtaining an additional license from Licensor.</p>

      <h3>3. Ownership</h3>
      <p><strong>3.1. Title:</strong> The Software is licensed, not sold. Licensor retains all right, title, and interest in and to the Software, including all intellectual property rights.</p>

      <h3>4. Confidentiality</h3>
      <p><strong>4.1. Confidential Information:</strong> Licensee acknowledges that the Software contains proprietary and confidential information of Licensor. Licensee agrees to take reasonable measures to protect the confidentiality of the Software.</p>

      <h3>5. Warranty and Disclaimer</h3>
      <p><strong>5.1. Warranty:</strong> The Software is provided "as is" without warranty of any kind. Licensor does not warrant that the Software will meet Licensee's requirements or that the operation of the Software will be uninterrupted or error-free.</p>
      <p><strong>5.2. Disclaimer:</strong> LICENSOR DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT.</p>

      <h3>6. Limitation of Liability</h3>
      <p><strong>6.1. Limitation:</strong> In no event shall Licensor be liable for any indirect, incidental, special, or consequential damages, or damages for loss of profits, revenue, data, or use, incurred by Licensee or any third party, whether in an action in contract or tort, arising from Licensee's access to, or use of, the Software.</p>

      <h3>7. Termination</h3>
      <p><strong>7.1. Termination:</strong> This Agreement is effective until terminated. Licensor may terminate this Agreement immediately upon written notice if Licensee breaches any provision of this Agreement.</p>
      <p><strong>7.2. Effect of Termination:</strong> Upon termination of this Agreement, Licensee shall cease all use of the Software and destroy all copies, full or partial, of the Software.</p>

      <h3>8. General</h3>
      <p><strong>8.1. Governing Law:</strong> This Agreement shall be governed by and construed in accordance with the laws of [Your Jurisdiction], without regard to its conflict of law principles.</p>
      <p><strong>8.2. Entire Agreement:</strong> This Agreement constitutes the entire agreement between the parties with respect to the subject matter hereof and supersedes all prior or contemporaneous understandings or agreements, written or oral, regarding such subject matter.</p>
      <p><strong>8.3. Amendments:</strong> No amendment or modification of this Agreement shall be binding unless in writing and signed by both parties.</p>
      <p><strong>8.4. Severability:</strong> If any provision of this Agreement is held to be invalid or unenforceable, the remaining provisions of this Agreement will remain in full force and effect.</p>
      <p><strong>8.5. Waiver:</strong> No waiver of any term or condition of this Agreement shall be deemed a continuing waiver of such term or condition or any other term or condition, and any failure of Licensor to enforce any right or provision of this Agreement shall not constitute a waiver of such right or provision.</p>

    </div>
  );
}

export default Terms;