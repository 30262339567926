import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import '../App.scss';
import { Button } from '@mui/base/Button';
import { useLocation } from 'react-router-dom';
import { Checkbox, FormControl, FormControlLabel, FormGroup, TextField } from '@mui/material';
import { CompleteReport, ReportClient } from '../types';

import { APIPATH } from '../App';

import { Container } from 'react-bootstrap';

function Recommendations() {
  const [completeReport, setCompleteReport] = useState<CompleteReport>();

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  var responseId = params.get('r');

  const reportClient = new ReportClient(APIPATH);

  const getReport = () => {
    if (responseId !== null) {
      reportClient.getReportFromResponseId(responseId)
        .then(resp => {
          if (resp !== undefined) {
            setCompleteReport(resp);
            console.log("got resp:" + resp);
          }
        })
        .catch(error => {
          console.error('Error fetching response:', error);
        });
    }
  };

  useEffect(() => {
    getReport();
  }, [responseId])

  const [r1, setR1] = useState(true);
  const [r2, setR2] = useState(true);
  const [r3, setR3] = useState(true);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const formData = { r1, r2, r3 };

    alert(JSON.stringify(formData));
  };

  return (
    <div className="main">
      <Container className="mb-8 mt-4 w-50">
        <div className="mb-6">
          <h1>Recommendations</h1>
          <h2 className="mt-4 mb-2">Optional Deliverables</h2>
          <form
            onSubmit={handleSubmit}
            className="d-flex flex-column align-items-center"
          >
            <FormControl component="fieldset">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={r1}
                      onChange={() => { setR1(!r1) }}
                      name="AD01"
                    />
                  }
                  label="General Business updates/communications"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={true}
                      onChange={() => { }}
                      name="AD02"
                    />
                  }
                  label="1:1 Updates (Face to Face, email, letter etc)"
                />
              </FormGroup>

              <h2 className="mt-4 mb-2">Potential Risks</h2>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={false}
                      onChange={() => { }}
                      name="R001"
                    />
                  }
                  label="Lack of understanding or belief In the reason for change"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={true}
                      onChange={() => { }}
                      name="R002"
                    />
                  }
                  label="Lack of clarity about the future vision for the change"
                />
                
              </FormGroup>

              <h2 className="mt-4 mb-2">Optional Readiness Checks</h2>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={false}
                      onChange={() => { }}
                      name="RA01"
                    />
                  }
                  label="Messages about the intended outcomes of this change are consistent and clear"
                />
              </FormGroup>
            </FormControl>
            <Button type="submit" className="styled-button mt-6">
              Submit
            </Button>
          </form>
        </div>

      </Container>
    </div>
  );
}

export default Recommendations;
