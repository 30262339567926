import React, { useEffect, useState } from 'react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { QuestionClient, QuestionWithChangeImpacts, ResponseClient, Responses } from '../types';
import ProgressBar from './progressBar';
import { APIPATH } from '../App';
import { useLocation, useNavigate } from 'react-router-dom';
import Project from '../routes/project';

type Props = {
  enabled: boolean;
}

export const Questionnaire = (props: Props) => {
  const defaultResponse: Responses = {
    projectId: "",
    questionChangeImpacts: undefined
  };

  const navigate = useNavigate();

  const [currentQuestionNumber, setCurrentQuestionNumber] = useState<number>(1);
  const [questionWithChangeImpacts, setQuestionWithChangeImpacts] = useState<QuestionWithChangeImpacts>();
  const [questionCount, setQuestionCount] = useState<number>(0);
  const [response, setResponse] = useState<Responses>(defaultResponse);

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  var responseId = params.get('r');

  const responseClient = new ResponseClient(APIPATH);
  const questionClient = new QuestionClient(APIPATH);

  const handleBack = () => {
    // Logic for handling the user's answer and moving to the next question
    setCurrentQuestionNumber((prevNumber) => prevNumber - 1);
  };

  const getTotalNumberOfQuestions = async () => {
    const questionCount = await questionClient.getTotalNumberOfQuestions();
    setQuestionCount(questionCount);
  };

  useEffect(() => {
    getTotalNumberOfQuestions();
  }, [])

  const getResponse = () => {
    if (responseId !== null) {
      responseClient.getResponseFromId(responseId)
        .then(resp => {
          if (resp !== undefined) {
            setResponse(resp);
          }
        })
        .catch(error => {
          console.error('Error fetching response:', error);
        });
    }
  };

  useEffect(() => {
    getResponse();
  }, [responseId])

  const getNextQuestionWithChangeImpacts = async () => {
    const questionWithImpacts = await questionClient.getQuestionWithChangeImpacts(currentQuestionNumber);
    setQuestionWithChangeImpacts(questionWithImpacts);
  };

  useEffect(() => {
    getNextQuestionWithChangeImpacts();
  }, [currentQuestionNumber])

  const [selectedChangeImpactId, setSelectedChangeImpactId] = useState<string | null>(null);

  const handleOptionClick = (questionNumber: number | undefined, id: string | undefined) => {
    if (questionNumber !== undefined && id !== undefined) {
      // Update the response object
      const updatedResponse: Responses = {
        ...response,
        questionChangeImpacts: {
          ...response.questionChangeImpacts,
          [questionNumber]: id
        }
      };

      // Update the state with the updated response
      setResponse(updatedResponse);
      // Set the selected change impact id
      setSelectedChangeImpactId(id);
    }
  };

  const handleReviseClick = () => {
    setCurrentQuestionNumber(1); 
  }

  const handleReviseProjectClick = () => {
    navigate(`../project/?p=` + response.projectId, { replace: true });
  }

  const handleSubmitClick = () => {
    responseClient.submitResponse(response)
      .then((resp) => {
        navigate(`../thank-you`, { replace: true });
      })
      .catch((error) => {
        console.error('Error submitting response:', error);
      });
  };

  const handleNextClick = () => {
    // Call the upsertResponse method with the updated response
    responseClient.upsertResponse(response)
    .then(() => {
      if (selectedChangeImpactId !== undefined) {
        if (currentQuestionNumber !== questionCount +1) {
          setCurrentQuestionNumber(currentQuestionNumber + 1);
        }
      } else {
        alert('Please select an answer before proceeding.');
      }
    })
    .catch((error) => {
      console.error('Error updating response:', error);
    });
  };

  return (
    <div className="main container questionnaire-grid">
      {questionCount !== 0 && (
        <ProgressBar progress={(currentQuestionNumber / (questionCount +1)) * 100} />
      )}
      {currentQuestionNumber > 1 && (
        <a
          className="mb-4"
          data-testid="continue-button"
          type="submit"
          onClick={handleBack}
        >
          <ArrowBackIosIcon />Back
        </a>
      )}
      {questionCount > 0 && questionCount !==  -1 && questionWithChangeImpacts && response && (
        <>
          {currentQuestionNumber > 0 && currentQuestionNumber !== questionCount +1 && (
            <>
              <h1 className="mb-2 text-center">People impact assessment</h1> 
              <h2 className="mb-4 text-center">{questionWithChangeImpacts.area?.hint}</h2>
            </>
          )}
          <h4 className="mb-4">{questionWithChangeImpacts.questions?.description}</h4>
          <div className="mb-4">
            {questionWithChangeImpacts.changeImpacts?.sort((a, b) => (a.score ?? Infinity) - (b.score ?? Infinity)).map((changeImpact) => (
              <a
                key={changeImpact._id}
                className="q-answer text-decoration-none d-block bg-gray-100 py-4 px-4 rounded-md cursor-pointer mb-2"
                style={{
                  backgroundColor: changeImpact.score === 0 ? "#e1e1ed" :
                    changeImpact.score === 2 ? "#e6fae1" :
                      changeImpact.score === 4 ? "#f7ba99" :
                        changeImpact.score === 6 ? "#f5c4ca" : "#cccccc",
                  // @ts-ignore object could be undefined
                  border: response?.questionChangeImpacts !== null && response?.questionChangeImpacts !== undefined && response?.questionChangeImpacts[currentQuestionNumber] === changeImpact._id ? '4px solid #156570' : '1px solid gray',
                }}
                onClick={() => handleOptionClick(questionWithChangeImpacts.questions?._id, changeImpact._id)}
              >
                <strong>{changeImpact.rating}</strong> - {changeImpact.impact}
              </a>
            ))}
          </div>
        </>
      )}
      {currentQuestionNumber > 1 && currentQuestionNumber === questionCount +1 && (
        <div>
          <div className="mb-4">
            <h1 className="mb-6 text-center">Final review</h1>
            <p>Thank you for providing your project information. Either go back to check your answers, or click 'Finish and Submit' to submit your response.</p>
            <p>Once submitted, we'll process your responses. If you need to make a change following submission, please contact us to unlock this submission to edit it.</p>
            <p>Shortly after submission, we will send you the recommended tasks, risks, and readiness checks for your change.</p>
          </div>
          <button
            className="styled-button mt-4"
            data-testid="finish-submit"
            type="submit"
            // @ts-ignore object could be undefined
            onClick={handleSubmitClick}
          >
            Finish and Submit
          </button>
          <button
            className="styled-button mt-4 d-block"
            data-testid="finish-submit"
            type="submit"
            // @ts-ignore object could be undefined
            onClick={handleReviseClick}
          >
            Check or Revise Responses
          </button>
          <button
            className="styled-button mt-4 d-block"
            data-testid="finish-submit"
            type="submit"
            // @ts-ignore object could be undefined
            onClick={handleReviseProjectClick}
          >
            Check or Revise Project
          </button>
        </div>
      )}
      {currentQuestionNumber !== questionCount +1 && (
        <button
          className="styled-button"
          data-testid="continue-button"
          type="submit"
          // @ts-ignore object could be undefined
          disabled={!response || !(response.questionChangeImpacts !== undefined && response.questionChangeImpacts !== null && response.questionChangeImpacts[currentQuestionNumber])}
          onClick={handleNextClick}
        >
          Continue
        </button>
      )}

      {questionWithChangeImpacts?.questions?.hint !== undefined && (  
        <div className="mt-6" dangerouslySetInnerHTML={{ __html: questionWithChangeImpacts.questions.hint }} />
      )}
      </div>
  );
};
