import React, { useEffect, useState } from "react";
import "../App.scss";
import {
  Checkbox,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import WavingHandIcon from "@mui/icons-material/WavingHand";
import { Container, Table } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import {
  CompleteReport,
  ProjectStatus,
  ReportClient,
  ResponseClient,
} from "../types";
import { APIPATH } from "../App";
import { Button } from "@mui/base/Button";
import { saveAs } from "file-saver";
import Papa from "papaparse";
// @ts-ignore missing types file
import html2pdf from "html2pdf.js";

function Report() {
  const [completeReport, setCompleteReport] = useState<CompleteReport>();

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  var responseId = params.get("r");

  const reportClient = new ReportClient(APIPATH);
  const responseClient = new ResponseClient(APIPATH);

  const getRagColor = (status: string) => {
    switch (status) {
      case "Red":
        return "#ff4d4d";
      case "Amber":
        return "#ffbf00";
      case "Green":
        return "#00b300";
      case "Blue":
        return "#0073e6";
      case "Grey":
        return "#808080";
      default:
        return "#fff";
    }
  };

  const getReport = () => {
    if (responseId !== null) {
      reportClient
        .getReportFromResponseId(responseId)
        .then((resp) => {
          if (resp !== undefined) {
            setCompleteReport(resp);
          }
        })
        .catch((error) => {
          console.error("Error fetching response:", error);
        });
    }
  };

  useEffect(() => {
    getReport();
  }, [responseId]);

  const getColor = (score: number): string => {
    switch (score) {
      case 0:
        return "#e1e1ed"; // No impact
      case 1:
        return "#e6fae1"; // Not Used
      case 2:
        return "#e6fae1"; // Very Low
      case 3:
        return "#d4f5a4"; // Low
      case 4:
        return "#f7ba99"; // Medium
      case 5:
        return "#f2ced2"; // High
      case 6:
        return "#ed4c4c"; // Very High
      default:
        return "#cccccc"; // Default color
    }
  };

  const handleRemoveByAdmin = (
    id: string,
    type: "risk" | "deliverable" | "check",
    checked: boolean
  ) => {
    setCompleteReport((prev) => {
      if (!prev || !prev.response) return prev;

      switch (type) {
        case "risk":
          const updatedRisks = prev.response.risks?.map((item) =>
            item.riskId === id ? { ...item, removedByAdmin: checked } : item
          );
          return {
            ...prev,
            response: { ...prev.response, risks: updatedRisks },
          };

        case "deliverable":
          const updatedDeliverables = prev.response.deliverables?.map((item) =>
            item.deliverableId === id
              ? { ...item, removedByAdmin: checked }
              : item
          );
          return {
            ...prev,
            response: { ...prev.response, deliverables: updatedDeliverables },
          };

        case "check":
          const updatedChecks = prev.response.checks?.map((item) =>
            item.checkId === id ? { ...item, removedByAdmin: checked } : item
          );
          return {
            ...prev,
            response: { ...prev.response, checks: updatedChecks },
          };

        default:
          return prev;
      }
    });
  };

  const handleRejectByClient = (
    id: string,
    type: "risk" | "deliverable" | "check",
    checked: boolean
  ) => {
    setCompleteReport((prev) => {
      if (!prev || !prev.response) return prev;

      switch (type) {
        case "risk":
          const updatedRisks = prev.response.risks?.map((item) =>
            item.riskId === id ? { ...item, rejectedByClient: checked } : item
          );

          return {
            ...prev,
            response: { ...prev.response, risks: updatedRisks },
          };

        case "deliverable":
          const updatedDeliverables = prev.response.deliverables?.map((item) =>
            item.deliverableId === id
              ? { ...item, rejectedByClient: checked }
              : item
          );
          return {
            ...prev,
            response: { ...prev.response, deliverables: updatedDeliverables },
          };

        case "check":
          const updatedChecks = prev.response.checks?.map((item) =>
            item.checkId === id ? { ...item, rejectedByClient: checked } : item
          );
          return {
            ...prev,
            response: { ...prev.response, checks: updatedChecks },
          };

        default:
          return prev;
      }
    });
  };

  const handleRiskRationaleChange = (id: string, rationale: string) => {
    setCompleteReport((prev) => {
      if (!prev || !prev.response) return prev;

      const updatedRisks = prev.response.risks?.map((item) =>
        item.riskId === id ? { ...item, rationale: rationale } : item
      );

      return { ...prev, response: { ...prev.response, risks: updatedRisks } };
    });
  };

  const handleDeliverableRationaleChange = (id: string, rationale: string) => {
    setCompleteReport((prev) => {
      if (!prev || !prev.response) return prev;

      const updatedDeliverables = prev.response.deliverables?.map((item) =>
        item.deliverableId === id ? { ...item, rationale: rationale } : item
      );

      return {
        ...prev,
        response: { ...prev.response, deliverables: updatedDeliverables },
      };
    });
  };

  const handleRagStatusChange = (id: string, ragStatus: string) => {
    setCompleteReport((prev) => {
      if (!prev || !prev.response) return prev;

      const updatedChecks = prev.response.checks?.map((item) =>
        item.checkId === id ? { ...item, ragStatus: ragStatus } : item
      );

      console.log(updatedChecks);

      return { ...prev, response: { ...prev.response, checks: updatedChecks } };
    });
  };

  // For the CSV's the user rationale is not needed, the purpose is intended to create a task management in another application. The rag status is needed to track progress in the task management.

  const downloadChecksAsCSV = () => {
    if (!completeReport || !completeReport.response?.checks) return;

    const csvData = completeReport.response.checks
      .map((responseCheck) => {
        const check = completeReport.checks?.find(
          (c) =>
            c._id === responseCheck.checkId &&
            !responseCheck.removedByAdmin &&
            !responseCheck.rejectedByClient
        );
        if (!check) return null;

        return {
          Checks: check.description,
          "RAG Status": responseCheck.ragStatus || "",
          areaId: check.areaId || ""
        };
      })
      .filter((data): data is { Checks: string | undefined; "RAG Status": string; areaId: string } => data !== null) // Narrow the type to exclude null
      .sort((a, b) => {
        // Sort by areaId
        if (a!.areaId && b!.areaId) {
          return a!.areaId.localeCompare(b!.areaId);
        }
        return 0;
      });

    // Remove areaId before exporting to CSV
    const csvDataForExport = csvData.map(({ areaId, ...rest }) => rest);

    const csv = Papa.unparse(csvDataForExport);

    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, "Project_Checks.csv");
  };

  const downloadRisksAsCSV = () => {
    if (!completeReport || !completeReport.response?.risks) return;

    const csvData = completeReport.response.risks
      .map((responseRisk) => {
        const risk = completeReport.risks?.find(
          (c) =>
            c._id === responseRisk.riskId &&
            !responseRisk.removedByAdmin &&
            !responseRisk.rejectedByClient
        );
        if (!risk) return null;

        return {
          Risks: risk.description,
          areaId: risk.areaIds || "", // Include areaId for sorting
        };
      })
      .filter((data): data is { Risks: string | undefined; areaId: string } => data !== null) // Narrow the type
      .sort((a, b) => {
        // Sort by areaId
        return a.areaId.localeCompare(b.areaId);
      });

    // Remove areaId before exporting to CSV
    const csvDataForExport = csvData.map(({ areaId, ...rest }) => rest);

    const csv = Papa.unparse(csvDataForExport);

    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, "Project_Risks.csv");
  };

  const downloadDeliverablesAsCSV = () => {
    if (!completeReport || !completeReport.response?.deliverables) return;

    const csvData = completeReport.response.deliverables
      .map((responseDeliverable) => {
        const deliverable = completeReport.deliverables?.find(
          (c) =>
            c._id === responseDeliverable.deliverableId &&
            !responseDeliverable.removedByAdmin &&
            !responseDeliverable.rejectedByClient
        );
        if (!deliverable) return null;

        return {
          Deliverables: deliverable.description,
          areaId: deliverable.areaId || "", // Include areaId for sorting
        };
      })
      .filter((data): data is { Deliverables: string | undefined; areaId: string } => data !== null) // Narrow the type
      .sort((a, b) => {
        // Sort by areaId
        return a.areaId.localeCompare(b.areaId);
      });

    // Remove areaId before exporting to CSV
    const csvDataForExport = csvData.map(({ areaId, ...rest }) => rest);

    const csv = Papa.unparse(csvDataForExport);

    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, "Project_Deliverables.csv");
  };


  const downloadAsPDF = () => {
    const element = document.getElementById("content-to-download");
    if (!element) {
      return;
    }

    const hiddenElements = document.querySelectorAll(".hidden-content");
    hiddenElements.forEach((el) => {
      (el as HTMLElement).style.display = "block";
    });

    const contentToHideElements = document.querySelectorAll(
      ".content-to-hide-printing"
    );
    contentToHideElements.forEach((el) => {
      (el as HTMLElement).style.display = "none";
    });

    element.classList.add("report-print-out");

    const opt = {
      margin: 0, // Optional, sets the page margin (default is 0)
      filename: "journey-report.pdf",
      image: { type: "jpeg", quality: 0.98 }, // Optional, sets the image quality (default is 0.95)
      html2canvas: { scale: 2 }, // Optional, scales the canvas elements (default is 2)
      jsPDF: { unit: "pt", format: "letter", orientation: "portrait" }, // Optional, sets the PDF format and orientation (default is 'a4' and 'portrait')
      pagebreak: {
        mode: ["avoid-all", "css"],
      },
    };

    html2pdf()
      .set(opt)
      .from(element)
      .save()
      .then(() => {
        // Hide the hidden content again after generating the PDF
        element.classList.remove("report-print-out");

        contentToHideElements.forEach((el) => {
          (el as HTMLElement).style.display = "block";
        });
        hiddenElements.forEach((el) => {
          (el as HTMLElement).style.display = "none";
        });
      });
  };

  const upsertResponse = () => {
    if (completeReport?.response) {
      responseClient
        .upsertResponse(completeReport?.response)
        .then((resp: any) => { })
        .catch((error: any) => {
          console.error("Error submitting response:", error);
        });
    }
  };

  const upsertAdminResponse = () => {
    if (completeReport?.response) {
      responseClient
        .upsertResponse(completeReport?.response)
        .then((resp: any) => { })
        .catch((error: any) => {
          console.error("Error submitting response:", error);
        });
    }

    const updatedProject = completeReport?.project;
    if (updatedProject) {
      updatedProject.status = ProjectStatus.Complete;

      // Update the project and refresh the window
      responseClient.upsertProject(updatedProject).then(() => {
        window.location.reload();
      });
    }
  };

  const getAreaDescriptionById = (
    areaId: string | undefined
  ): string | undefined => {
    // if (completeReport?.project?.status !== ProjectStatus.AwaitingAdminApproval) {
    //   return undefined;
    // }

    if (!completeReport?.areas) {
      return undefined;
    }

    const area = completeReport.areas.find((a) => a._id === areaId);
    return "\n " + area?.description;
  };

  const getAreaDescriptionByIds = (
    areaIds: string[] | undefined
  ): string | undefined => {
    // if (completeReport?.project?.status !== ProjectStatus.AwaitingAdminApproval) {
    //   return undefined;
    // }

    if (!completeReport?.areas || !areaIds || areaIds.length === 0) {
      return undefined;
    }

    const descriptions =
      "\n" +
      areaIds
        .map(
          (areaId) =>
            completeReport.areas?.find((a) => a._id === areaId)?.description
        )
        .filter((description) => description !== undefined)
        .join("\n");

    return descriptions.length > 0 ? descriptions : undefined;
  };

  const formatDate = (dateString: string | undefined) => {
    if (dateString !== undefined) {
      const date = new Date(dateString);
      return date.toLocaleDateString("en-GB"); // en-GB locale for DD-MM-YYYY format
    }
  };

  return (
    <div className="main" id="content-to-download">
      {completeReport?.response && (
        <Container className="mb-3">
          <div className="mb-2">
            <div>
              <img alt="Journey Front Page" src="/journey-report-frontpage.jpg" className="hidden-content page-break-after" style={{ height: '990px', width: 'auto', marginLeft: '25px' }} />
            </div>
            <div className="hidden-content text-center">
              <div className="w-50 mb-9 mx-auto">
                <svg
                  className="main-svg-title"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 576 151.5"
                >
                  <path
                    fill="#22505f"
                    d="M478.3 42.1c-3.8-4-8.4-7.2-13.8-9.5-5.3-2.3-11.1-3.5-17.3-3.5s-12 1.2-17.2 3.7c-5.3 2.5-9.8 5.7-13.7 9.8-3.8 4.1-6.8 8.7-9 14.1-2.1 5.3-3.2 10.9-3.2 16.6 0 6.1 1.2 11.8 3.5 17.1s5.5 9.9 9.5 13.8c4 4 8.6 7 13.8 9.3 5.2 2.2 10.8 3.3 16.7 3.3 8.7 0 16.5-2.5 23.4-7.4 3.5-2.4 6.6-5.3 9.5-8.8s5.3-7.6 7.3-12.2h-25.2c-1.7 2.2-3.7 4-5.9 5.5-2.3 1.5-5.3 2.2-9 2.2-4.8 0-9-1.3-12.7-4.1-3.6-2.7-5.8-6.5-6.6-11.5H490c.2-1 .3-1.9.4-2.7s.2-2.1.2-3.8c0-6.2-1.1-12.1-3.3-17.5-2.1-5.6-5.2-10.3-9-14.4zm-49.6 21.8c.3-1.2.8-2.6 1.5-4.2s1.8-3.2 3.3-4.7 3.3-2.7 5.6-3.7 5-1.5 8.1-1.5c3.2 0 5.9.5 8.1 1.5 2.3 1 4.1 2.2 5.6 3.7s2.6 3 3.3 4.7 1.3 3 1.5 4.2h-37zM394.5 44.8c-.9-2.1-1.9-3.8-3-5.1s-2.2-2.5-3.3-3.5c-2.3-2-5.3-3.6-9.3-5-3.9-1.4-7.8-2.1-11.8-2.1-3.6 0-6.8.4-9.3 1.3-2.6.8-4.7 1.9-6.4 3s-3.1 2.4-4.1 3.8c-1 1.3-1.9 2.5-2.4 3.5h-.3V32h-22.9v82h24.6V69.3c0-1.3.1-2.9.2-4.8s.7-3.8 1.5-5.6c.9-1.8 2.3-3.4 4.1-4.7 1.9-1.3 4.6-2 8.1-2 3.3 0 5.8.6 7.5 1.9 1.7 1.2 3 2.7 3.8 4.5s1.3 3.6 1.4 5.5c.1 1.9.2 3.6.2 5.1V114h24.6V62.7c0-4-.3-7.5-.9-10.4-.7-3-1.4-5.5-2.3-7.5zM51.5 77.6c0 1.3-.1 2.7-.1 4.3-.1 1.6-.4 3.1-.9 4.5S49.2 89 48 90s-2.8 1.5-4.9 1.5c-1.9 0-3.4-.4-4.4-1.3s-1.9-1.9-2.4-3.2-.9-2.7-1.1-4.1-.4-2.9-.4-4.1H4.9c0 6.2.9 11.4 2.6 15.7 1.7 4.2 3.9 7.7 6.4 10.5 2.6 2.8 5.4 4.9 8.4 6.4 3.1 1.5 5.9 2.6 8.7 3.3 2.7.7 5.1 1.1 7 1.2 2 .1 3.2.1 3.7.1 5.6 0 11.3-1.2 16.9-3.5s10.3-6 14.1-10.8c1.2-1.5 2.2-3.1 3-4.7.8-1.7 1.6-3.7 2.1-6.1s1-5.2 1.3-8.4c.2-3.3.4-7.2.4-11.8V4.5h-28v73.1zM548.6 32h-.5v44.5c0 11-4.9 16.7-14.4 16.7-9.3 0-11.8-5.2-11.8-16.7V32h-24.3v54.2c0 18.8 11.2 29.5 28.2 29.5 10.1 0 17.6-3.7 22.8-11.4v5.5c0 13.1-2.9 20.5-12.9 20.5-7.2 0-10.7-3.8-12.3-9.4H498c1.7 17.7 20.3 28.2 37.8 28.2 15.3 0 25.1-7.3 29.8-14 5.5-7.9 6.3-18.3 6.3-29.2V32h-23.3z"
                  ></path>
                  <path
                    fill="#589199"
                    d="M305.6 30.3c-2.4.8-4.4 1.9-5.9 3s-2.7 2.4-3.6 3.8-1.6 2.5-2.1 3.5h-.3v-8.7h-23.1V114h24.6V68.9c0-3.1.5-5.6 1.4-7.5.9-2 2.3-3.5 4-4.6s3.8-1.8 6.1-2.2c2.4-.4 5-.6 7.8-.7V29.1c-3.5 0-6.5.4-8.9 1.2zM164.9 43.5c-3.6-4.2-8.2-7.6-13.8-10.3-5.5-2.7-12-4-19.5-4s-14 1.3-19.5 4-10.1 6.1-13.8 10.3-6.4 8.9-8.2 14.1-2.7 10.3-2.7 15.3.9 10.1 2.7 15.3 4.6 9.9 8.2 14.1c3.6 4.2 8.2 7.6 13.8 10.3 5.5 2.7 12 4 19.5 4s14-1.3 19.5-4 10.1-6.1 13.8-10.3c3.6-4.2 6.4-8.9 8.2-14.1 1.8-5.2 2.7-10.3 2.7-15.3s-.9-10.1-2.7-15.3c-1.8-5.2-4.6-9.9-8.2-14.1zm-33.3 50.2c-22.7-7.7-21.5-22.9-21.5-22.9 0-7.2 5.5-13.1 12.3-13.1 3.7 0 7 1.8 9.2 4.5 2.3-2.7 5.5-4.5 9.2-4.5 6.8 0 12.3 5.8 12.3 13.1 0 .1 1.2 15.2-21.5 22.9zM234.5 77c0 3.3-.4 6-1.2 8.1-.8 2.2-1.9 3.9-3.2 5.1-1.3 1.2-2.8 2.1-4.5 2.7-1.7.5-3.4.8-5.2.8-3.3 0-5.8-.6-7.6-1.8-1.8-1.2-3.1-2.6-3.9-4.3s-1.3-3.5-1.4-5.4-.2-3.6-.2-5V31.9h-24.6V81c0 2.9.1 5.4.2 7.6s.5 4.3 1 6.2 1.3 3.8 2.2 5.6 2.2 3.8 3.9 5.8c1.8 2.3 3.8 4.1 6.1 5.5 2.3 1.4 4.5 2.4 6.8 3.2s4.4 1.2 6.4 1.4 3.6.3 4.9.3c1.6 0 3.3-.1 5.3-.3 1.9-.2 3.9-.7 5.8-1.5s3.9-1.9 5.6-3.4c1.8-1.5 3.3-3.5 4.6-6.2h.3v8.7H259v-82h-24.6V77h.1z"
                  ></path>
                </svg>
              </div>
            </div>

            <div className="mb-2">
              <h1>Report</h1>
              <p>
                <strong>Your Name: </strong>
                {completeReport?.user?.name}
              </p>
              <p>
                <strong>Your Role: </strong>
                {completeReport?.user?.role}
              </p>
              <p>
                <strong>Your Company: </strong>
                {completeReport?.user?.company}
              </p>
              <p>
                <strong>Your Email: </strong>
                {completeReport?.user?.email}
              </p>
            </div>

            <div className="mb-2 page-break-after">
              <p>
                <strong>Project Title: </strong>
                {completeReport?.project?.projectTitle}
              </p>
              <p>
                <strong>Company Reference Number: </strong>
                {completeReport?.project?.companyReferenceNumber}
              </p>
              <p>
                <strong>Journey Reference: </strong>
                <span
                  onClick={() => {
                    navigator.clipboard.writeText(window.location.href);
                  }}
                >
                  {completeReport?.response?._id} - click to copy
                </span>
              </p>
              <p>
                <strong>Programme Title (if applicable): </strong>
                {completeReport?.project?.programmeTitle}
              </p>
              <p>
                <strong>Business Unit Accountable: </strong>
                {completeReport?.project?.businessUnitAccountable}
              </p>
              <p>
                <strong>Start Date: </strong>
                {formatDate(completeReport?.project?.startDate)}
              </p>
              <p>
                <strong>Finish Date: </strong>
                {formatDate(completeReport?.project?.finishDate)}
              </p>
            </div>

            <div className="mb-2 ">
              <h2 className="mt-4 mb-2">Project Brief</h2>
              <p>
                <strong>Overview of Change: </strong>
                {completeReport?.project?.overviewOfChange}
              </p>
            </div>

            <div className="mb-2">
              <h2 className="mt-4 mb-2">
                Initial People Experience Assessment
              </h2>
              <p>
                This is your initial assessment on the impacts to people before
                during and after the project has been implemented.
              </p>
              <p>
                <strong>People Experience Today: </strong>
                {completeReport?.project?.peopleExperienceToday}
              </p>
              <p>
                <strong>People Experience Future: </strong>
                {completeReport?.project?.peopleExperienceFuture}
              </p>
            </div>

            <div className="mb-2">
              <h3 className="mt-4 mb-2">Core People Metrics</h3>
              <Table bordered hover>
                <thead>
                  <tr>
                    <th style={{ width: "50%" }}>
                      Expected Change in Experience
                    </th>
                    <th style={{ width: "25%" }}>As-is</th>
                    <th style={{ width: "25%" }}>To-be</th>
                  </tr>
                </thead>
                <tbody>
                  {completeReport?.project?.corePeopleMetrics?.map(
                    (metric, index) => (
                      <tr key={index}>
                        <td>{metric.expectedChangeInExperience}</td>
                        <td>{metric.asIs}</td>
                        <td>{metric.toBe}</td>
                      </tr>
                    )
                  )}
                </tbody>
              </Table>
            </div>

            <div className="mb-6 ">
              <h3 className="mt-4 mb-2">Critical Success Factors</h3>
              <Table bordered hover>
                <thead>
                  <tr>
                    <th>Critical Success Factor</th>
                  </tr>
                </thead>
                <tbody>
                  {completeReport?.project?.criticalSuccessFactors?.map(
                    (factor, index) => (
                      <tr key={index}>
                        <td>{factor}</td>
                      </tr>
                    )
                  )}
                </tbody>
              </Table>
            </div>

            <div className="mb-6 page-break-after">
              <h3 className="mt-4 mb-2">People Affected</h3>
              <Table bordered hover>
                <thead>
                  <tr>
                    <th>Team or Unit Affected</th>
                    <th>FTE Count</th>
                  </tr>
                </thead>
                <tbody>
                  {completeReport?.project?.peopleAffected?.map(
                    (person, index) => (
                      <tr key={index}>
                        <td>{person.teamOrUnitAffected}</td>
                        <td>{person.fteCount}</td>
                      </tr>
                    )
                  )}
                </tbody>
              </Table>
            </div>

            <div className="mb-6">
              <h2 className="mt-4 mb-2">People Impact Assessment</h2>
              <p>
                This section shows your assessment of the people impact in the 6
                key management priority areas:
              </p>
            </div>

            <div className="mb-6 page-break-after">
              {completeReport?.areas
                ?.sort((a, b) => (a._id ?? "").localeCompare(b._id ?? ""))
                ?.map((area, tableIndex) => (
                  <div key={tableIndex} className="">
                    <h3>{area.description}</h3>
                    <Table bordered hover>
                      <thead>
                        <tr>
                          <th style={{ width: "600px" }}>Question</th>
                          <th className="text-center small-column" style={{ width: "30px" }}>
                            Selected Score
                          </th>
                          <th>Impact</th>
                        </tr>
                      </thead>
                      <tbody>
                        {completeReport?.response?.questionChangeImpacts &&
                          Object.entries(
                            completeReport.response.questionChangeImpacts
                          ).map(([questionId, changeImpactId], index) => {
                            const question = completeReport.questions?.find(
                              (q) =>
                                q._id === Number.parseInt(questionId) &&
                                q.areaId === area._id
                            );
                            const changeImpact =
                              completeReport.changeImpacts?.find(
                                (ci) =>
                                  ci._id === changeImpactId &&
                                  ci.questionId === Number.parseInt(questionId)
                              ); //  && ci.areaId ===
                            return (
                              question && (
                                <tr key={index}>
                                  <td>{question?.description}</td>
                                  <td
                                    className="text-center small-column"
                                    style={{
                                      backgroundColor: getColor(
                                        changeImpact?.score
                                          ? changeImpact?.score
                                          : 0
                                      ),
                                    }}
                                  >
                                    {changeImpact?.rating}
                                  </td>
                                  <td>{changeImpact?.impact}</td>
                                </tr>
                              )
                            );
                          })}
                      </tbody>
                    </Table>
                  </div>
                ))}
            </div>

            <div className="mb-6">
              <h2 className="mt-4 mb-2">People Impact - Focus Areas</h2>
              <Table bordered hover className="text-center">
                <thead>
                  <tr>
                    <th>Communication &amp; Vision</th>
                    <th>Leadership</th>
                    <th>Learning &amp; Development</th>
                    <th>Organisational Enablers</th>
                    <th>Environment</th>
                    <th>Systems, Processes and Procedures</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td
                      className="text-center small-column"
                      style={{
                        backgroundColor: getColor(
                          completeReport?.response?.communicationAndVisionScore
                            ? completeReport?.response
                              ?.communicationAndVisionScore
                            : 0
                        ),
                      }}
                    >
                      {completeReport?.response?.communicationAndVisionRanking}
                    </td>
                    <td
                      className="text-center small-column"
                      style={{
                        backgroundColor: getColor(
                          completeReport?.response?.leadershipScore
                            ? completeReport?.response?.leadershipScore
                            : 0
                        ),
                      }}
                    >
                      {completeReport?.response?.leadershipRanking}
                    </td>
                    <td
                      className="text-center small-column"
                      style={{
                        backgroundColor: getColor(
                          completeReport?.response?.learningAndDevelopmentScore
                            ? completeReport?.response
                              ?.learningAndDevelopmentScore
                            : 0
                        ),
                      }}
                    >
                      {completeReport?.response?.learningAndDevelopmentRanking}
                    </td>
                    <td
                      className="text-center small-column"
                      style={{
                        backgroundColor: getColor(
                          completeReport?.response?.organisationalEnablersScore
                            ? completeReport?.response
                              ?.organisationalEnablersScore
                            : 0
                        ),
                      }}
                    >
                      {completeReport?.response?.organisationalEnablersRanking}
                    </td>
                    <td
                      className="text-center small-column"
                      style={{
                        backgroundColor: getColor(
                          completeReport?.response?.environmentScore
                            ? completeReport?.response?.environmentScore
                            : 0
                        ),
                      }}
                    >
                      {completeReport?.response?.environmentRanking}
                    </td>
                    <td
                      className="text-center small-column"
                      style={{
                        backgroundColor: getColor(
                          completeReport?.response?.systemsScore
                            ? completeReport?.response?.systemsScore
                            : 0
                        ),
                      }}
                    >
                      {completeReport?.response?.systemsRanking}
                    </td>
                  </tr>
                  <tr>
                    <td
                      className="text-center small-column"
                      style={{
                        backgroundColor: getColor(
                          completeReport?.response?.communicationAndVisionScore
                            ? completeReport?.response
                              ?.communicationAndVisionScore
                            : 0
                        ),
                      }}
                    >
                      {completeReport?.response?.communicationAndVisionScore}
                    </td>
                    <td
                      className="text-center small-column"
                      style={{
                        backgroundColor: getColor(
                          completeReport?.response?.leadershipScore
                            ? completeReport?.response?.leadershipScore
                            : 0
                        ),
                      }}
                    >
                      {completeReport?.response?.leadershipScore}
                    </td>
                    <td
                      className="text-center small-column"
                      style={{
                        backgroundColor: getColor(
                          completeReport?.response?.learningAndDevelopmentScore
                            ? completeReport?.response
                              ?.learningAndDevelopmentScore
                            : 0
                        ),
                      }}
                    >
                      {completeReport?.response?.learningAndDevelopmentScore}
                    </td>
                    <td
                      className="text-center small-column"
                      style={{
                        backgroundColor: getColor(
                          completeReport?.response?.organisationalEnablersScore
                            ? completeReport?.response
                              ?.organisationalEnablersScore
                            : 0
                        ),
                      }}
                    >
                      {completeReport?.response?.organisationalEnablersScore}
                    </td>
                    <td
                      className="text-center small-column"
                      style={{
                        backgroundColor: getColor(
                          completeReport?.response?.environmentScore
                            ? completeReport?.response?.environmentScore
                            : 0
                        ),
                      }}
                    >
                      {completeReport?.response?.environmentScore}
                    </td>
                    <td
                      className="text-center small-column"
                      style={{
                        backgroundColor: getColor(
                          completeReport?.response?.systemsScore
                            ? completeReport?.response?.systemsScore
                            : 0
                        ),
                      }}
                    >
                      {completeReport?.response?.systemsScore}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>

            <div className="mb-6">
              <h3 className="mt-4 mb-2">
                Priority Key of People Impact Ratings
              </h3>
              <Table bordered hover className="text-center">
                <thead>
                  <tr>
                    <th>No Impact</th>
                    <th>Very Low</th>
                    <th>Low</th>
                    <th>Medium</th>
                    <th>High</th>
                    <th>Very High</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td
                      className="text-center small-column"
                      style={{ backgroundColor: getColor(0) }}
                    >
                      0
                    </td>
                    <td
                      className="text-center small-column"
                      style={{ backgroundColor: getColor(2) }}
                    >
                      2
                    </td>
                    <td
                      className="text-center small-column"
                      style={{ backgroundColor: getColor(3) }}
                    >
                      3
                    </td>
                    <td
                      className="text-center small-column"
                      style={{ backgroundColor: getColor(4) }}
                    >
                      4
                    </td>
                    <td
                      className="text-center small-column"
                      style={{ backgroundColor: getColor(5) }}
                    >
                      5
                    </td>
                    <td
                      className="text-center small-column"
                      style={{ backgroundColor: getColor(6) }}
                    >
                      6
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>

            <div>
              <h2>Recommendations and Deliverables</h2>
            </div>

            <Table striped bordered hover>
              <thead>
                <tr>
                  <th style={{ width: "50%" }}>Description</th>
                  {completeReport?.project?.status ===
                    ProjectStatus.AwaitingAdminApproval && (
                      <th>Admin - Remove Suggestion</th>
                    )}
                  <th className="text-center small-column">Reject</th>
                  <th>Rationale</th>
                </tr>
              </thead>
              <tbody>
                {completeReport?.response?.deliverables
                  ?.filter(
                    (responseDeliverable) =>
                      !responseDeliverable?.removedByAdmin
                  )
                  .map((responseDeliverable) => {
                    const deliverable = completeReport?.deliverables?.find(
                      (d) => d._id === responseDeliverable?.deliverableId
                    );
                    return deliverable
                      ? { responseDeliverable, deliverable }
                      : null;
                  })
                  .filter((item) => item !== null)
                  .sort((a, b) => {
                    if (a!.deliverable.areaId && b!.deliverable.areaId) {
                      return a!.deliverable.areaId.localeCompare(
                        b!.deliverable.areaId
                      );
                    }
                    return 0;
                  })
                  .map((item, index) => (
                    <tr key={item!.deliverable._id}>
                      <td style={{ whiteSpace: "pre-line" }}>
                        <strong>{item!.deliverable.description}</strong>{" "}
                        {getAreaDescriptionById(item!.deliverable.areaId)}
                      </td>
                      {completeReport.project?.status ===
                        ProjectStatus.AwaitingAdminApproval && (
                          <td>
                            <Checkbox
                              checked={
                                item!.responseDeliverable.removedByAdmin || false
                              }
                              onChange={(e) =>
                                handleRemoveByAdmin(
                                  item!.deliverable._id || "",
                                  "deliverable",
                                  e.target.checked
                                )
                              }
                            />
                          </td>
                        )}
                      <td className="text-center small-column">
                        <Checkbox
                          checked={
                            item!.responseDeliverable.rejectedByClient || false
                          }
                          onChange={(e) =>
                            handleRejectByClient(
                              item!.deliverable._id || "",
                              "deliverable",
                              e.target.checked
                            )
                          }
                        />
                      </td>
                      <td>
                        <>
                          <TextField
                            className="content-to-hide-printing"
                            value={item!.responseDeliverable.rationale}
                            onChange={(e) =>
                              handleDeliverableRationaleChange(
                                item!.deliverable._id || "",
                                e.target.value
                              )
                            }
                            fullWidth
                          />{" "}
                          <span className="hidden-content">
                            {item!.responseDeliverable.rationale || ""}
                          </span>
                        </>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>

            <p>
              <WavingHandIcon /> You can now transfer all the accepted
              deliverables to your project plan.
            </p>

            <Button
              type="submit"
              className="styled-button mt-6 d-block exclude-from-pdf page-break-after"
              onClick={downloadDeliverablesAsCSV}
            >
              Download Deliverables as CSV
            </Button>

            <h2 className="mt-4 mb-2">Potential Risks</h2>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th style={{ width: "50%" }}>Description</th>
                  {completeReport?.project?.status ===
                    ProjectStatus.AwaitingAdminApproval && (
                      <th>Admin - Remove Suggestion</th>
                    )}
                  <th className="text-center small-column">Reject</th>
                  <th>Rationale</th>
                </tr>
              </thead>
              <tbody>
                {completeReport?.response?.risks?.map((responseRisk) => {
                  if (responseRisk?.removedByAdmin) {
                    return null;
                  }

                  const risk = completeReport?.risks?.find(
                    (d) => d._id === responseRisk?.riskId
                  );

                  if (!risk) {
                    return null;
                  }

                  return (
                    <tr key={risk._id}>
                      <td style={{ whiteSpace: "pre-line" }}>
                        <strong>{risk.description} </strong>
                        {getAreaDescriptionByIds(risk.areaIds)}
                      </td>
                      {completeReport.project?.status ===
                        ProjectStatus.AwaitingAdminApproval && (
                          <td>
                            <Checkbox
                              checked={responseRisk?.removedByAdmin || false}
                              onChange={(e) =>
                                handleRemoveByAdmin(
                                  risk._id || "",
                                  "risk",
                                  e.target.checked
                                )
                              }
                            />
                          </td>
                        )}
                      <td className="text-center small-column">
                        <Checkbox
                          checked={responseRisk?.rejectedByClient || false}
                          onChange={(e) =>
                            handleRejectByClient(
                              risk._id || "",
                              "risk",
                              e.target.checked
                            )
                          }
                        />
                      </td>
                      <td>
                        <>
                          <TextField
                            className="content-to-hide-printing"
                            value={responseRisk?.rationale || ""}
                            onChange={(e) =>
                              handleRiskRationaleChange(
                                risk._id || "",
                                e.target.value
                              )
                            }
                            fullWidth
                          />
                          <span className="hidden-content">
                            {responseRisk?.rationale || ""}
                          </span>
                        </>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            <p>
              <WavingHandIcon /> Add the accepted risks to your risk management
              log for regular review and mitigation (before they become risks)
            </p>

            <Button
              type="submit"
              className="styled-button mt-6 d-block exclude-from-pdf page-break-after"
              onClick={downloadRisksAsCSV}
            >
              Download Risks as CSV
            </Button>

            <h2 className="mt-4 mb-2">Readiness Checks</h2>

            <Table striped bordered hover>
              <thead>
                <tr>
                  <th className="checks-description-column" style={{ width: "70%" }}>Description</th>
                  {completeReport?.project?.status ===
                    ProjectStatus.AwaitingAdminApproval && (
                      <th>Admin - Remove Suggestion</th>
                    )}
                  <th className="medium-column">RAG Status</th>
                </tr>
              </thead>
              <tbody>
                {completeReport?.response?.checks
                  ?.filter((responseCheck) => !responseCheck?.removedByAdmin)
                  .map((responseCheck) => {
                    const check = completeReport?.checks?.find(
                      (d) => d._id === responseCheck?.checkId
                    );
                    return check ? { responseCheck, check } : null;
                  })
                  .filter((item) => item !== null)
                  .sort((a, b) => {
                    if (a!.check.areaId && b!.check.areaId) {
                      return a!.check.areaId.localeCompare(b!.check.areaId);
                    }
                    return 0;
                  })
                  .map((item, index) => (
                    <tr key={item!.check._id}>
                      <td style={{ whiteSpace: "pre-line" }} className="checks-description-column">
                        <strong>{item!.check.description}</strong>{" "}
                        {getAreaDescriptionById(item!.check.areaId)}
                      </td>
                      {completeReport.project?.status ===
                        ProjectStatus.AwaitingAdminApproval && (
                          <td>
                            <Checkbox
                              checked={
                                item!.responseCheck.removedByAdmin || false
                              }
                              onChange={(e) =>
                                handleRemoveByAdmin(
                                  item!.check._id || "",
                                  "check",
                                  e.target.checked
                                )
                              }
                            />
                          </td>
                        )}
                      <td className="medium-column">
                        <Select
                          value={item!.responseCheck.ragStatus || ""}
                          onChange={(e) =>
                            handleRagStatusChange(
                              item!.check._id || "",
                              e.target.value as string
                            )
                          }
                          fullWidth
                          style={{
                            backgroundColor: getRagColor(
                              item!.responseCheck.ragStatus || ""
                            ),
                          }}
                        >
                          <MenuItem value="Grey">Not Applicable</MenuItem>
                          <MenuItem value="Red">Red</MenuItem>
                          <MenuItem value="Amber">Amber</MenuItem>
                          <MenuItem value="Green">Green</MenuItem>
                          <MenuItem value="Blue">Complete</MenuItem>
                        </Select>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>

            <p>
              <WavingHandIcon /> Use the readiness checks at regular
              intervals/reviews and before the final review/no-go decision.
            </p>

            <Button
              type="submit"
              className="styled-button mt-6 d-block exclude-from-pdf "
              onClick={downloadChecksAsCSV}
            >
              Download Readiness Checks as CSV
            </Button>

            {completeReport?.project?.status ===
              ProjectStatus.AwaitingAdminApproval && (
                <Button
                  type="submit"
                  className="styled-button mt-6 d-block mb-2 exclude-from-pdf"
                  onClick={upsertAdminResponse}
                >
                  Admin Complete and Submit
                </Button>
              )}

            {completeReport?.project?.status !==
              ProjectStatus.AwaitingAdminApproval && (
                <Button
                  type="submit"
                  className="styled-button mt-6 d-block mb-2 exclude-from-pdf"
                  onClick={upsertResponse}
                >
                  Save Changes
                </Button>
              )}

            <Button
              type="submit"
              className="styled-button mt-6 d-block exclude-from-pdf"
              onClick={downloadAsPDF}
            >
              Download Full Report as PDF
            </Button>
          </div>

          <div className="mb-6 page-break-after">
            <h2>Summary</h2>
            <p>All the information is based on the information provided.</p>
            <p>
              If you need to change the recommended deliverables, risks and
              readiness checks, you can do this here:{" "}
              <a href={window.location.href}>{window.location.href}</a>
            </p>
            <p>
              Any questions/problems contact{" "}
              <a href="mailto:enquiry@ourjourney.uk">enquiry@ourjourney.uk</a>
            </p>
          </div>

          <div className="mb-6 hidden-content">
            <h2>Your Notes</h2>
          </div>
        </Container>
      )}
    </div>
  );
}

export default Report;
