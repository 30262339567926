
import { useState } from 'react';
import '../App.scss';

function ThankYou() {
 
  return (
    <div className="main pb-4 d-flex flex-column align-items-center mb-4">
        <h1 className="mb-6">All done for now!</h1>
        <h4 className="mb-6">Thanks for using Journey.</h4>
        <p>You will hear back from us shortly.</p>
      </div>
  );
}

export default ThankYou;