
import { useState } from 'react';
import '../App.scss';

function ComingSoon() {
 
  return (
    <div className="main py-4 d-flex flex-column align-items-center align-content-center">
        <h4 className="mb-6">Welcome to Journey!</h4>
        <p>To use this platform, please get in touch with John.</p>
      </div>
  );
}

export default ComingSoon;