
import { useEffect, useState } from 'react';
import '../App.scss';
import { Button } from '@mui/base/Button';
import { TextField } from '@mui/material';

import { useLocation, useNavigate } from 'react-router-dom';

function Guide() {
    const navigate = useNavigate();

    // useEffect(() => {
    //     const handlePopState = (event: PopStateEvent) => {
    //         navigate(`../project/?p=${projectId}`);
    //     };

    //     window.addEventListener('popstate', handlePopState);

    //     return () => {
    //         window.removeEventListener('popstate', handlePopState);
    //     };
    // }, [navigate]);

    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const projectId = params.get('p');

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        navigate(`../project/?p=${projectId}`);
    };

    return (
        <div className="main container py-4 mb-6">
            <h1 className="text-center mb-4">Putting people at the heart of change.</h1>
            <h2>Introduction</h2>
            <p>
                The Journey application is a bespoke process that focuses on delivering change within your business. Journey quickly provides you with a structured approach for any given change scenario affecting people.
                The application helps to create advocates and champions within business; people who are committed to change, making the process more efficient and successful.
                It does not matter whether you are a seasoned change lead or just starting out. This will guide you from start to finish.
            </p>
            <h2 className="mb-3">Getting started</h2>
            <p>
                The type, timing and scale of the change can influence the need for people to understand &amp; commit to any proposed change. You will need to decide whom to involve in the assessment.
                Simply follow the same approach whether your change is minor or major. The only difference will be on how much time and effort you take to complete the Project Brief & Initial People Experience Assessment and the People Impact Assessment.
            </p>
            <h2 className="mb-3">Project brief &amp; initial people experience assessment</h2>
            <p>
                This section is designed to help you show the impacts to people before during and after the project has been implemented.
                It also considers the businesses critical success factors and core people metrics (optional)
                Complete with as much information as possible. It is a good reference document throughout the process/change project. It also ensures traceability if/when needed.
            </p>
            <h2 className="mb-3">People impact assessment</h2>
            <p>
                This section assesses the level of impact in 6 key people management priority areas:
            </p>
            <ul>
                <li>Shared change purpose – communication &amp; vision</li>
                <li>Effective change leadership – leadership &amp; sponsorship.</li>
                <li>Strong people connection – learning &amp; development.</li>
                <li>Manage change to jobs – organisational enablers.</li>
                <li>Capacity for change – environment.</li>
                <li>Tools to do the job – systems, processes and procedures.</li>
            </ul>
            <p>
                Working with the Accountable Executive and key stakeholders, answer the questions as honestly as you can to help you to identity the priority areas for action to manage the impact on staff who are on the receiving end of this change.
                Consider carefully (before selecting your answer) how the change affects individuals personally, including their roles, responsibilities, location and job security. They can influence their commitment to the change.
                Make sure you consider everyone affected however small (e.g. The specifically affected team(s) and wider teams in company/group, 3rd party suppliers and contractors/temporary staff).
                If, as you work through the questions you realise you need to change or check your response, simply go back and change your answers or click ‘Finish and Submit’ to submit your response.
                Once submitted, we will process your responses. If you need to make a change following submission, please contact us to unlock this submission to edit it.
            </p>
            <h2 className="mb-3">Submission</h2>
            <p>
                Shortly after submission, Journey will send you a list of deliverables, risks and readiness checks based on your responses. You will have the choice of agreeing or declining the recommendations.
            </p>
            <h2 className="mb-3">Deliverables</h2>
            <p>
                These are the deliverables/tasks that Journey recommends your project will need to put in place to best manage the People Side of the Change. You can accept or decline the recommendations. Your organisation may require a brief justification for any deliverables ‘declined’ for future traceability.
                You can simply integrate all/your selection into your project plan.
            </p>
            <h2 className="mb-3">Risks</h2>
            <p>
                Journey will highlight the key people related risks of not carrying out the recommended deliverables/tasks correctly, efficiently and robustly. Managing the risks will ensure that change initiatives are more likely to succeed, with less disruption and more support from those involved.
                You can accept or decline the recommendations. Your organisation may require a brief justification for any risks ‘declined’ for future traceability.
                You can simply integrate all/your selection into your risk log.
            </p>
            <h2 className="mb-3">Readiness checks</h2>
            <p>
                Journey provides these to help you to assess the people readiness for your project during each phase of the project. You can accept or decline the recommendations. Your organisation may require a brief justification for any readiness checks ‘declined’ for future traceability.
                You or your organisation will decide the frequency of assessments. The readiness checks must be used (as a minimum), with the accountable executive and key stakeholders prior to implementation - using the checklist as a 'Go/No Go' check for all parties to approve prior to Implementation.
            </p>
            <p>When ready, just get started by clicking…</p>
            <Button type="submit" className="styled-button" onClick={handleSubmit}>
                Continue
            </Button>
        </div>

    );
}

export default Guide;