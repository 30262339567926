
import { useEffect, useState } from 'react';
import '../App.scss';
import { Button } from '@mui/base/Button';
import { TextField } from '@mui/material';

import { useNavigate } from 'react-router-dom';

import { ResponseClient, Users } from '../types';
import { APIPATH } from '../App';

function Registration() {
  const [name, setName] = useState('');
  const [role, setRole] = useState('');
  const [company, setCompany] = useState('');
  const [email, setEmail] = useState('');
 
  const navigate = useNavigate();
  const responseClient = new ResponseClient(APIPATH); 

  useEffect(() => {
    try {
      responseClient.wakeUp();
    } catch (error) {
      console.error('Error during wake up call:', error);
    }
  }, []);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const user: Users =  { name, role, company, email }; 
    
    try {
      const createdUser = await responseClient.upsertUser(user);
      const createdProject = await responseClient.createProjectForUserId(createdUser._id);
      navigate(`../onboarding/?p=${createdProject._id}`, { replace: true });
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div className="main pb-4 d-flex flex-column align-items-center">
        <h4 className="mb-6">Please introduce yourself...</h4>

        <form onSubmit={handleSubmit} className="d-flex flex-column align-items-center">
          <TextField
            id="name"
            label="Your Name"
            variant="outlined"
            className="mb-4"
            value={name}
            style={{ minWidth: "300px" }}
            onChange={(e) => setName(e.target.value)}
            required
          />
          <TextField
            id="role"
            label="Your Role"
            variant="outlined"
            className="mb-4"
            style={{ minWidth: "300px" }}
            value={role}
            onChange={(e) => setRole(e.target.value)}
            required
          />
          <TextField
            id="company"
            label="Your Company"
            variant="outlined"
            className="mb-4"
            style={{ minWidth: "300px" }}
            value={company}
            onChange={(e) => setCompany(e.target.value)}
            required
          />
          <TextField
            id="email"
            label="Your Email"
            variant="outlined"
            className="mb-4"
            style={{ minWidth: "300px" }}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <Button type="submit" className="styled-button mb-4">
            Continue
          </Button>
          <p>* denotes mandatory fields</p>
        </form>

      </div>
  );
}

export default Registration;