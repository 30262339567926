
import '../App.scss';
import { Button } from '@mui/base/Button';
import { useLocation, useNavigate } from 'react-router-dom';

function Onboarding() {
  const navigate = useNavigate();
  
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const projectId = params.get('p');

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    navigate(`../registration`);
    navigate(`../project/?p=${projectId}`);
  };

  const handleReadMoreSubmit = async (e: any) => {
    e.preventDefault();
    navigate(`../guide/?p=${projectId}`);
  };

  return (
    <div className="main pb-4 d-flex flex-column align-items-center">
      <h1 className="mb-6">A quick intro from John, the founder of Journey...</h1>
      <div className="container mb-6">
        <div className="row px-md-12">
          <div className="col-md-4">
            <img alt="John" src="/John.jpg" className="img-fluid mb-4" />
          </div>
          <div className="col-md-8 align-content-center">
            <p>Journey was built to help businesses manage the people side of change with ease.</p>
            <p>In order to manage your change effectively, we'll assess 6 key areas (including shared purpose, leadership and engagement).</p>
            <p>Please provide details of your project, and then fill out a questionnaire to assess the impact of your change project on people.</p>
            <Button type="submit" className="styled-button mb-6 mt-6 me-4" onClick={handleReadMoreSubmit}>
              Read More
            </Button>
            <Button type="submit" className="styled-button mb-6" onClick={handleSubmit}>
              Get Started
            </Button>
          </div>
        </div>
      </div>

    </div>
  );
}

export default Onboarding;